import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Features from "../components/features";
import CTA from "../components/cta";
import SocialProof from "../components/socialproof";
// import FAQ from "../components/faq";
import Team from "../components/team";
import Pricing from "../components/pricing";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        keywords={[
          `analytics data you can trust`,
        ]}
        title="Analytics data you can trust."
      />

      <section className="max-w-4xl mx-auto">
        <Hero />
        {/* <CruxTool location={location}/> */}
        <div className="mt-16 md:mt-32">
          <SocialProof />
        </div>
        <CTA
          heading="Trust your analytics data."
          subheading="Start with a tracking plan."
          primaryButtonText="Get started"
          secondaryButtonText="Learn more"
        />
        <Features />
        <Pricing />
        <Team />
        {/* <FAQ /> */}
        <CTA
          heading="Ready to learn more?"
          subheading="Let's chat."
          primaryButtonText="Get started"
          secondaryButtonText="Learn more"
        />
      </section>
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
