import React from "react";

const Hero = () => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-screen-xl mx-auto ">
        <div className="relative z-10 pb-4 bg-white sm:pb-6 md:pb-12 lg:pb-16 xl:pb-20">
          <main className="mx-auto max-w-screen-xl px-4 mt-8 sm:mt-10 sm:px-6 md:mt-12 lg:mt-16 lg:px-8 xl:mt-20">
            <div className="text-center">
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Marketing attribution modeling you can trust.
                <br />
              </h2>
              <p className="mt-3 pb-16 md:pb-32 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl md:max-w-2xl">
                Hi! I&apos;m Jamie and I&apos;m an analytics engineer/geek. I
                work with growth marketing agencies that have clients that need
                help with attribution modeling. Use my point of view and repeatable process
                to create clean and consistent attribution data you can trust.
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Hero;
