import React from "react";
import SpotLogo from "../images/logos/socialproof/logo-spot.svg";
import SlipStreamLogoText from "../images/logos/socialproof/logo-slipstream-white.svg";
import SlipStreamLogo from "../images/logos/socialproof/logo-slipstream.svg";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const SocialProof = () => {
  const data = useStaticQuery(graphql`
    query {
      logoCLMA: file(
        relativePath: { eq: "logos/socialproof/logo-grayscale-clma.png" }
      ) {
        ...fluidImage
      }
      logoStacker: file(
        relativePath: { eq: "logos/socialproof/logo-stacker-black.png" }
      ) {
        ...fluidImage
      }
      logoFigments: file(
        relativePath: { eq: "logos/socialproof/logo-figments.png" }
      ) {
        ...fluidImage
      }
    }
  `);

  return (
    <div className="">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:text-center">
          <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            You probably shouldn&apos;t compete with these companies because
            they get analytics
          </p>
        </div>
        {/* <div className="flex flex-col md:flex-row md:justify-between mt-4 h-full"> */}
        <div className="flex flex-col my-4 mx-auto">
          <div className="flex justify-around pb-4 md:mx-auto md:w-1/2">
            <div className="flex my-auto">
              <Img
                className="w-32 h-8"
                fluid={data.logoStacker.childImageSharp.fluid}
              />
            </div>
            <div className="flex">
              <img
                className="w-8 h-8"
                src={SlipStreamLogo}
                alt="SlipStream Logo"
                style={{ filter: "grayscale(100%)" }}
              />
              <img
                className="pl w-28 h-8 my-auto"
                src={SlipStreamLogoText}
                alt="SlipStream"
                style={{ filter: "brightness(0.25)" }}
              />
            </div>
            <div className="flex my-auto">
              <img
                className="w-16 h-8"
                src={SpotLogo}
                alt="Spot Logo"
                style={{ filter: "grayscale(100%)" }}
              />
            </div>
          </div>
          <div className="flex justify-around md:mx-auto md:w-1/2">
            <div className="my-auto">
              <Img
                className="w-32 h-4"
                fluid={data.logoFigments.childImageSharp.fluid}
              />
            </div>
            <div className="flex my-auto">
              <Img
                className="w-8 h-8"
                fluid={data.logoCLMA.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialProof;
